import React from "react";

export const SplashLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 560.8 56.4"
    className="splash-logo"
  >
    <g>
      <path
        d="M6.5,10.7v-0.2h14.6c7.8,0,19.1,2.4,19.1,19.4c0,10-7.3,17.8-19.1,17.8H6.5v-0.2c0.8,0,2.4-1,2.4-2.9V13.4
		C8.9,11.5,7.4,10.7,6.5,10.7 M20.7,46.4c7.7,0,15.9-4.1,15.9-16.4c0-14.7-8.4-18.4-15.9-18.4h-8.4v34.7
		C12.3,46.4,17.7,46.4,20.7,46.4"
        fill="#F8F1E7"
      />
      <path
        d="M126.9,13.4c0-1.9-1.2-2.8-2-2.8v-0.2h5.6v0.2c-0.8,0-2,0.8-2,2.8V48h-0.2L101,16.3v28.3
		c0,1.9,1.5,2.8,2.4,2.8v0.2H97v-0.2c0.8,0,2.4-0.8,2.4-2.8V14.2l-3.8-3.8h4.8l26.5,30.8V13.4z"
        fill="#F8F1E7"
      />
      <path
        d="M186,10.4v3.3h-0.2c-0.4-1-0.8-1.6-2.4-1.6c-0.4,0-17.2,0-17.3,0v14.2l12.6-0.1c1.9,0,2.8-1.2,2.8-2h0.2v5.3
		h-0.2c0-0.8-0.9-2-2.8-2L166,27.4v17.2c0,1.9,1.1,2.8,1.9,2.8v0.2h-7.1v-0.2c0.8,0,1.9-0.9,1.9-2.8V13.4c0-1.9-1.1-2.8-1.9-2.8
		v-0.2H186z"
        fill="#F8F1E7"
      />
      <path
        d="M199.6,47.4c0.8,0,2-0.8,2-2.8V13.4c0-1.9-1.2-2.8-2-2.8v-0.2h7.3v0.2c-0.8,0-2,0.8-2,2.8v31.2
		c0,1.9,1.2,2.8,2,2.8v0.2h-7.3V47.4z"
        fill="#F8F1E7"
      />
      <path
        d="M251.9,13.4c0-1.9-1.2-2.8-2-2.8v-0.2h5.6v0.2c-0.8,0-2,0.8-2,2.8V48h-0.2L226,16.3v28.3
		c0,1.9,1.5,2.8,2.4,2.8v0.2H222v-0.2c0.8,0,2.4-0.8,2.4-2.8V14.2l-3.8-3.8h4.8l26.5,30.8V13.4z"
        fill="#F8F1E7"
      />
      <path
        d="M270.7,10.7v-0.2h7.3v0.2c-0.8,0-2,0.8-2,2.8v31.2c0,1.9,1.2,2.8,2,2.8v0.2h-7.3v-0.2c0.8,0,2-0.8,2-2.8V13.4
		C272.6,11.5,271.4,10.7,270.7,10.7 M292.5,43.7l-15.9-16.1v-0.1l13.1-13.5c1.2-1.2,1.4-1.9,1.4-3.5h7v0.2c-1.2,0-3.7,0.7-5.7,2.8
		l-12.4,13.1l14.7,14.8c1.8,1.9,6.2,6.3,9.1,6.3v0.2h-1.4C297.7,47.8,295.1,46.3,292.5,43.7"
        fill="#F8F1E7"
      />
      <path
        d="M325.9,45.7l-0.3-4.7h0.2c0.7,4.1,5.9,6.2,9.6,6.2c4.8,0,8.8-3.3,8.8-7.5c0-9.4-19.5-10.4-19.5-21.1
		c0-5.3,5.3-8.9,10.2-8.9c4.6,0,8.8,3,8.8,3l-0.3,3.5h-0.2c-0.3-1.7-3.9-5.6-8.1-5.6c-3,0-8.2,2-8.2,7.1c0,7.5,19.7,9.5,19.7,20.3
		c0,4.7-3.7,10.3-11.2,10.3C330.6,48.3,325.9,45.7,325.9,45.7"
        fill="#F8F1E7"
      />
      <path
        d="M358.6,11.8c-1.6,0.1-2.3,1.2-2.7,2.2l-0.2,0v-3.5h31.4V14l-0.2,0c-0.4-1-1.1-2.1-2.7-2.2
		c-2.3-0.1-7.1-0.2-11.1-0.2v33c0,1.9,1.1,2.8,1.9,2.8v0.2h-7.2v-0.2c0.8,0,1.9-0.9,1.9-2.8v-33C365.9,11.6,360.9,11.7,358.6,11.8"
        fill="#F8F1E7"
      />
      <path
        d="M432.3,10.4v0.2c-0.8,0-2,0.8-2,2.8v31.2c0,1.9,1.2,2.8,2,2.8v0.2h-6.8v-0.2c0.6,0,1.5-0.8,1.5-2.8v-1.5
		c-2,1.7-6.7,5.2-12.9,5.2c-8,0-13.3-5.9-13.3-15.4V13.4c0-1.9-1.2-2.8-2-2.8v-0.2h7.4v0.2c-0.8,0-2,0.8-2,2.8v20
		c0,7.3,5.1,13.7,11.8,13.7c3.9,0,8.7-2.8,11.1-4.8V13.4c0-1.9-1.2-2.8-2-2.8v-0.2H432.3z"
        fill="#F8F1E7"
      />
      <path
        d="M447.4,10.7v-0.2H462c7.8,0,19.1,2.4,19.1,19.4c0,10-7.3,17.8-19.1,17.8h-14.6v-0.2c0.8,0,2.4-1,2.4-2.9V13.4
		C449.8,11.5,448.2,10.7,447.4,10.7 M461.5,46.4c7.7,0,15.9-4.1,15.9-16.4c0-14.7-8.4-18.4-15.9-18.4h-8.4v34.7
		C453.1,46.4,458.6,46.4,461.5,46.4"
        fill="#F8F1E7"
      />
      <path
        d="M494.5,47.4c0.8,0,2-0.8,2-2.8V13.4c0-1.9-1.2-2.8-2-2.8v-0.2h7.3v0.2c-0.8,0-2,0.8-2,2.8v31.2
		c0,1.9,1.2,2.8,2,2.8v0.2h-7.3V47.4z"
        fill="#F8F1E7"
      />
      <path
        d="M552.6,29.8c0,12.4-10.6,18.5-19.3,18.5c-8.7,0-19.1-6.1-19.1-19.4c0-13.2,10.4-19.2,19.4-19.2
		C541,9.7,552.6,14.3,552.6,29.8 M549,30.1c0-12.1-8.6-19.4-16.1-19.4c-7.1,0-15.2,5.2-15.2,16.8c0,11.6,7.6,19.7,16.7,19.7
		C540.7,47.2,549,42,549,30.1"
        fill="#F8F1E7"
      />
      <path
        d="M48.9,47.4c1.2,0,2.8-0.7,3.7-2.8l15-34.9h0.2l14.9,34.9c0.8,1.9,2.8,2.8,3.6,2.8v0.2h-8.4v-0.2
		c0.8,0,1.3-0.5,1.3-1.4c0-0.5-0.2-1.2-0.5-1.8l-2.9-7.1H57.6l-3,7.1c-0.2,0.5-0.3,1-0.3,1.4c0,1.2,0.8,1.9,1.5,1.9v0.2h-6.9V47.4z
		 M75.4,35.9L67,15.4l-8.8,20.5H75.4z"
        fill="#F8F1E7"
      />
    </g>
  </svg>
);
