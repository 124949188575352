import React, { useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Context
import { PageBackgroundColor } from "../context/page-background-color";

// Components
import { ProjectsNavigation } from "../navigation/projects-navigation";

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 25px 40px;

  & *:focus {
    outline: ${props => props.textColor} dashed 1px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }

  z-index: 200;
  color: ${props => props.textColor};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & ol.studio-link {
    padding: 0;
    margin: 0 0 0 auto;

    & li {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.15em;

      & a {
        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        transition: 250ms color ease;

        &.current {
          color: ${props => props.hoverTextColor};
        }

        &:hover {
          color: ${props => props.hoverTextColor};
        }
      }
    }
  }

  & ol.homepage-mobile-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    padding: 22px 30px;

    & li {
      font-size: 11px;
      line-height: 1;
      letter-spacing: 0.15em;

      font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
        sans-serif;

      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
    }
  }
`;

export const Footer = ({
  pageType,
  textColor,
  hoverTextColor,
  location,
  isDesktop,
}) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  return (
    <FooterWrapper
      bgColor={pageBackgroundColor}
      textColor={textColor}
      hoverTextColor={hoverTextColor}
    >
      {isDesktop ? (
        <>
          {pageType === `Projects` && (
            <ProjectsNavigation location={location} />
          )}

          <ol className="desktop-navigation studio-link">
            <li className="uppercase">
              <Link
                to={`/studio`}
                className={pageType === `Studio` ? `current` : ``}
              >
                Studio
              </Link>
            </li>
          </ol>
        </>
      ) : (
        <>
          {pageType === `Home` && (
            <ol className="homepage-mobile-navigation">
              <li className="uppercase flex-1">
                <Link
                  to={`/design`}
                  className={pageType === `Design` ? `current` : ``}
                >
                  Design
                </Link>
              </li>
              <li className="uppercase flex-1">
                <Link
                  to={`/experience`}
                  className={pageType === `Experience` ? `current` : ``}
                >
                  Experience
                </Link>
              </li>
              <li className="uppercase">
                <Link
                  to={`/studio`}
                  className={pageType === `Studio` ? `current` : ``}
                >
                  Studio
                </Link>
              </li>
            </ol>
          )}
        </>
      )}
    </FooterWrapper>
  );
};
