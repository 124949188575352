import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";
import { Normalize } from "styled-normalize";
import { useSessionStorage } from "react-use";
import mobile from "is-mobile";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useHasMounted } from "../hooks/useHasMounted";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { DefaultSEO } from "./default-seo";
import { App } from "./app";

// Context
import { PageBackgroundColor } from "../context/page-background-color";
import { PageType } from "../context/page-type";

const Layout = ({ children }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useState(`#FFFDFA`);
  const [pageType, setPagetype] = useState(``);

  // Is Mobile
  const isMobile = mobile();

  // Splash
  const [hideSplash, setHideSplash] = useSessionStorage("hideSplash", false);
  const [removeSplash, setRemoveSplash] = useSessionStorage(
    "removeSplash",
    false
  );

  // Splash Video
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  // Rehydration stuff
  const hasMounted = useHasMounted();

  const data = useStaticQuery(graphql`
    {
      allPrismicOverviewSection {
        edges {
          node {
            id
            uid
            data {
              sections {
                page {
                  url
                }
              }
              background_color
              title {
                text
              }
            }
          }
        }
      }
    }
  `);

  const designLink = data.allPrismicOverviewSection.edges.filter(
    section => section.node.data.title.text === "Design"
  )[0].node.data.sections[0].page.url;

  const experienceLink = data.allPrismicOverviewSection.edges.filter(
    section => section.node.data.title.text === "Experience"
  )[0].node.data.sections[0].page.url;

  const studioLink = data.allPrismicOverviewSection.edges.filter(
    section => section.node.data.title.text === "Studio"
  )[0].node.data.sections[0].page.url;

  // Script for dealing with focus on tab key rather than on click
  // https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
  const handleFirstTab = e => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);

    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  const triggerHideSplash = () => {
    setHideSplash(true);

    const removeSplashScreen = setTimeout(() => {
      setRemoveSplash(true);
    }, 500);
    return () => clearTimeout(removeSplashScreen);
  };

  useEffect(() => {
    if (isVideoLoaded) {
      const timeoutForSplashScreen = setTimeout(() => {
        setHideSplash(true);
        const timeoutForRemoveSplash = setTimeout(() => {
          setRemoveSplash(true);
        }, 500);
        return () => clearTimeout(timeoutForRemoveSplash);
      }, 6000);
      return () => clearTimeout(timeoutForSplashScreen);
    }
  }, [isVideoLoaded]);

  useEffect(() => {
    if (hideSplash && removeSplash) {
      setHideSplash(true);
      setRemoveSplash(true);
    }
  }, [hideSplash, removeSplash]);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (!window.ResizeObserver) {
        window.ResizeObserver = require("resize-observer-polyfill");
      }
    }
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <PageType.Provider value={[pageType, setPagetype]}>
      <PageBackgroundColor.Provider
        value={[pageBackgroundColor, setPageBackgroundColor]}
      >
        <Location>
          {({ location }) => {
            return (
              <>
                <Normalize />
                <GlobalStyles />
                <DefaultSEO location={location} />

                <App
                  hideSplash={hideSplash}
                  removeSplash={removeSplash}
                  setRemoveSplash={setRemoveSplash}
                  pageType={pageType}
                  location={location}
                  designLink={designLink}
                  experienceLink={experienceLink}
                  studioLink={studioLink}
                  pageBackgroundColor={pageBackgroundColor}
                  isDesktop={isDesktop}
                  isVideoLoaded={isVideoLoaded}
                  setIsVideoLoaded={setIsVideoLoaded}
                  setHideSplash={setHideSplash}
                  triggerHideSplash={triggerHideSplash}
                  isMobile={isMobile}
                  children={children}
                />
              </>
            );
          }}
        </Location>
      </PageBackgroundColor.Provider>
    </PageType.Provider>
  );
};

export default Layout;
