import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) button:focus > a > svg,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) a:focus > svg,
body:not(.user-is-tabbing) ul:focus,
body:not(.user-is-tabbing) ul:focus li,
body:not(.user-is-tabbing) li:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) img:focus {
  outline: none;
}

*:focus{
  outline-offset: 3px;
}

body {
  font-family: "domaine-sans-text-web-light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;

  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media(max-width: 1280px){
    font-size: 15px;
    line-height: 25px;
  }
}

p {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

em {
  font-family: "domaine-sans-text-web-light-italic", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;
}

input {
  border-radius: 0;
  background-color: transparent;
  border:0;
  padding:0;
  margin:0;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

h1,h2,h3,h4,h5{
  font-family: "domaine-sans-text-web-light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.3px;
}

/* Title 2 */
h2 {
  font-size: 19px;
  line-height: 34px;
  letter-spacing: 0.15em;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}

.uppercase{
  text-transform: uppercase;
}

.center-text{
  text-align:center;
}

strong {
  font-weight: normal;
  font-style: normal;

  font-size: 10px;
  line-height: 28px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.soehne-extra-light,
.soehne-extra-light > h1,
.soehne-extra-light > h2,
.soehne-extra-light > h3,
.soehne-extra-light > p {
  font-family: "soehne-breit-web-extraleicht", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.soehne-light,
.soehne-light > h1,
.soehne-light > h2,
.soehne-light > h3,
.soehne-light > p {
  font-family: "soehne-breit-web-leicht", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.soehne-buch,
.soehne-buch > h1,
.soehne-buch > h2,
.soehne-buch > h3,
.soehne-buch > p {
  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande", sans-serif;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}



/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
  object-position: center;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;

    padding:0;
    margin:0;
    -webkit-appearance:none;
    color: inherit;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

::-webkit-input-placeholder{
  color: #F8F1E8 !important;
  font-size: 18px !important;
}

::-moz-placeholder{
  color: #F8F1E8 !important;
  font-size: 18px !important;
}

:-ms-input-placeholder{
  color: #F8F1E8 !important;
  font-size: 18px !important;
}

:-moz-placeholder {
  color: #F8F1E8 !important;
  font-size: 18px !important;
}


/*--------------------------------------------------------------
## General modals
--------------------------------------------------------------*/
.ReactModalPortal,
.ReactModal__Overlay--after-open {
  z-index: 100000;
}

.ReactModal__Content{
  height: 100%;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
`;

export default GlobalStyle;
