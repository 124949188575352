import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const NavigationContainer = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease-in-out;
`;

const List = styled.li`
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.15em;

  margin: 0 20px 0 0;
  cursor: pointer;

  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;

  transition: 250ms color ease;

  &.current {
    color: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    color: rgba(0, 0, 0, 0.5);

    & ol {
      color: #000;
    }
  }

  & a.current {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 0.5);

      & ol {
        color: #000;
      }
    }
  }

  & ol {
    position: absolute;
    bottom: 45px;

    background-color: rgba(255, 255, 255, 0.9);

    padding: 15px 20px 20px 20px;
    margin: 0 0 0 -20px;

    display: ${props => props.isSubMenuVisible};

    & li {
      margin: 0;
      line-height: 22px;
    }
  }
`;

export const ProjectsNavigation = ({
  hideProjects,
  hideDesignElements,
  bgColor,
  location,
}) => {
  const [isProjectsMenuOpen, setIsProjectsMenuOpen] = useState(false);
  const [isDesignElementsMenuOpen, setIsDesignElementsMenuOpen] = useState(
    false
  );

  useEffect(() => {
    setIsProjectsMenuOpen(false);
    setIsDesignElementsMenuOpen(false);
  }, [location]);

  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setOpacity(0);

    const fadeOutForm = setTimeout(() => {
      setOpacity(1);
    }, 500);
    return () => clearTimeout(fadeOutForm);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicPage(uid: { eq: "elements" }) {
        data {
          body {
            ... on PrismicPageBodyImageWithText {
              id
              slice_type
              primary {
                section_title {
                  text
                }
              }
            }
            ... on PrismicPageBodySingleImage {
              id
              slice_type
            }
            ... on PrismicPageBodyText {
              id
              slice_type
            }
            ... on PrismicPageBodyTitle {
              id
              slice_type
            }
          }
        }
      }
      prismicOverviewSection(uid: { eq: "design" }) {
        data {
          sections {
            page {
              document {
                ... on PrismicContact {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicOverviewSection {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPress {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicProjects {
                  id
                  data {
                    title {
                      text
                    }
                    projects {
                      project {
                        url
                        document {
                          ... on PrismicProject {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              url
            }
          }
        }
      }
    }
  `);

  const allDesignElementSlideIds = data.prismicPage.data.body
    .filter(item => item.slice_type === "image_with_text")
    .map(item => item.id);

  const firstDesignElementSlideId = allDesignElementSlideIds[0];

  const designElementsNavigation = data.prismicPage.data.body.map(
    (page, index) => {
      if (page.slice_type === "image_with_text") {
        return (
          <List key={`single_design_elements_nav_item_${index}`}>
            <Link
              to={`/design`}
              state={{
                slideId: {
                  section: page.primary.section_title.text,
                  id: page.id,
                },
              }}
            >
              {page.primary.section_title.text}
            </Link>
          </List>
        );
      }
    }
  );

  const navigation = data.prismicOverviewSection.data.sections.map(
    (page, index) => {
      if (page.page.document.data.title.text === "Projects") {
        const projectsMenu = page.page.document.data.projects
          .filter(project => project.project.document !== null)
          .map((project, index) => (
            <List
              key={`single_sub_nav_item_${project.project.document.id}_${index}`}
            >
              <Link to={project.project.url} activeClassName="current">
                {project.project.document.data.title.text}
              </Link>
            </List>
          ));

        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
            onMouseEnter={() => setIsProjectsMenuOpen(true)}
            onMouseLeave={() => setIsProjectsMenuOpen(false)}
            isSubMenuVisible={isProjectsMenuOpen ? `block` : `none`}
            bgColor={bgColor}
          >
            {!hideProjects && <ol>{projectsMenu}</ol>}

            <Link
              to={`/projects`}
              activeClassName="current"
              partiallyActive={true}
            >
              {page.page.document.data.title.text}
            </Link>
          </List>
        );
      } else if (page.page.document.data.title.text === "Elements") {
        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
            onMouseEnter={() => setIsDesignElementsMenuOpen(true)}
            onMouseLeave={() => setIsDesignElementsMenuOpen(false)}
            isSubMenuVisible={isDesignElementsMenuOpen ? `block` : `none`}
            bgColor={bgColor}
          >
            {!hideDesignElements && <ol>{designElementsNavigation}</ol>}

            <Link
              to={`/design`}
              state={{
                slideId: { section: "Elements", id: firstDesignElementSlideId },
              }}
            >
              {page.page.document.data.title.text}
            </Link>
          </List>
        );
      } else {
        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
          >
            <Link
              to={`/design`}
              state={{
                slideId: {
                  section: page.page.document.data.title.text,
                  id: page.page.document.id,
                },
              }}
            >
              {page.page.document.data.title.text}
            </Link>
          </List>
        );
      }
    }
  );

  return (
    <NavigationContainer opacity={opacity}>{navigation}</NavigationContainer>
  );
};
