import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  display: block;
  margin: 0 auto;

  width: 310px;
  height: auto;

  @media (max-width: 767px) {
    width: 260px;
  }
`;

export const Logo = ({ fill }) => (
  <Icon
    width="365"
    height="26"
    viewBox="0 0 365 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.45924 24.5531C14.5718 24.5531 20.0864 21.7808 20.0864 13.6179C20.0864 3.82068 14.4477 1.2965 9.45924 1.2965H3.82049V24.5232C3.82049 24.5232 7.48696 24.5531 9.45924 24.5531ZM0 0.646205V0.492188H9.76727C14.9739 0.492188 22.5208 2.12648 22.5208 13.4297C22.5208 20.1123 17.6222 25.3532 9.76727 25.3532H0V25.1991C0.556174 25.1991 1.60007 24.5531 1.60007 23.2568V2.49441C1.60007 1.20238 0.556174 0.646205 0 0.646205Z"
      fill={fill}
    />
    <path
      d="M80.4786 2.49441C80.4786 1.20238 79.6786 0.646205 79.1523 0.646205V0.492188H82.9086V0.646205C82.3867 0.646205 81.5824 1.20238 81.5824 2.49441V25.6313H81.4583L63.1687 4.4068V23.3509C63.1687 24.643 64.187 25.1991 64.7389 25.1991V25.3532H60.4863V25.1991C61.0082 25.1991 62.0564 24.643 62.0564 23.3509V3.02064L59.5322 0.496466H62.7366L80.4829 21.1049V2.49441H80.4786Z"
      fill={fill}
    />
    <path
      d="M119.963 0.492188V2.67838H119.809C119.531 2.00241 119.287 1.60026 118.209 1.60026C117.931 1.60026 106.687 1.60026 106.623 1.57031V11.0894L115.034 11.0295C116.326 11.0295 116.912 10.2295 116.912 9.70329H117.036V13.2457H116.912C116.912 12.6895 116.326 11.8895 115.034 11.8895L106.623 11.8296V23.3509C106.623 24.643 107.363 25.2291 107.915 25.2291V25.3532H103.141V25.2291C103.663 25.2291 104.403 24.643 104.403 23.3509V2.49441C104.403 1.20238 103.663 0.646205 103.141 0.646205V0.492188H119.963Z"
      fill={fill}
    />
    <path
      d="M129.045 25.1991C129.567 25.1991 130.372 24.643 130.372 23.3509V2.49441C130.372 1.20238 129.572 0.646205 129.045 0.646205V0.492188H133.91V0.646205C133.383 0.646205 132.583 1.20238 132.583 2.49441V23.3509C132.583 24.643 133.383 25.2291 133.91 25.2291V25.3532H129.041V25.1991H129.045Z"
      fill={fill}
    />
    <path
      d="M164.045 2.49441C164.045 1.20238 163.245 0.646205 162.719 0.646205V0.492188H166.48V0.646205C165.958 0.646205 165.153 1.20238 165.153 2.49441V25.6313H165.029L146.731 4.4068V23.3509C146.731 24.643 147.749 25.1991 148.301 25.1991V25.3532H144.049V25.1991C144.571 25.1991 145.619 24.643 145.619 23.3509V3.02064L143.095 0.496466H146.299L164.045 21.1049V2.49441Z"
      fill={fill}
    />
    <path
      d="M191.119 22.7049L180.491 11.9537V11.8595L189.24 2.8324C190.011 2.03236 190.165 1.54036 190.165 0.492188H194.879V0.646205C194.079 0.646205 192.385 1.1382 191.059 2.49441L182.742 11.2435L192.539 21.1647C193.711 22.4568 196.697 25.3532 198.61 25.3532V25.4772H197.656C194.631 25.4772 192.907 24.489 191.119 22.7049ZM176.547 0.646205V0.492188H181.445V0.646205C180.924 0.646205 180.119 1.20238 180.119 2.49441V23.3509C180.119 24.643 180.919 25.1991 181.445 25.1991V25.3532H176.547V25.1991C177.069 25.1991 177.873 24.643 177.873 23.3509V2.49441C177.873 1.20238 177.073 0.646205 176.547 0.646205Z"
      fill={fill}
    />
    <path
      d="M213.511 24.0613L213.327 20.951H213.481C213.943 23.6933 217.425 25.1094 219.919 25.1094C223.124 25.1094 225.836 22.9233 225.836 20.0868C225.836 13.772 212.805 13.156 212.805 6.00701C212.805 2.4646 216.317 0.0302734 219.641 0.0302734C222.692 0.0302734 225.524 2.06245 225.524 2.06245L225.34 4.37271H225.186C224.972 3.23469 222.568 0.646343 219.795 0.646343C217.793 0.646343 214.281 2.00255 214.281 5.36099C214.281 10.3537 227.436 11.7057 227.436 18.9487C227.436 22.059 224.942 25.8196 219.919 25.8196C216.625 25.8154 213.511 24.0613 213.511 24.0613Z"
      fill={fill}
    />
    <path
      d="M235.33 1.41629C234.252 1.47619 233.82 2.21633 233.512 2.89657L233.388 2.86662V0.492188H254.398V2.86662L254.274 2.89657C253.996 2.22061 253.534 1.48047 252.456 1.41629C250.916 1.32217 247.681 1.29222 245.063 1.29222V23.3509C245.063 24.643 245.803 25.2291 246.355 25.2291V25.3532H241.551V25.2291C242.107 25.2291 242.843 24.643 242.843 23.3509V1.29222C240.229 1.29222 236.9 1.32645 235.33 1.41629Z"
      fill={fill}
    />
    <path
      d="M284.599 0.492188V0.646205C284.043 0.646205 283.243 1.20238 283.243 2.49441V23.3509C283.243 24.643 284.043 25.2291 284.599 25.2291V25.3532H280.068V25.1991C280.436 25.1991 281.052 24.643 281.052 23.3509V22.3627C279.696 23.5007 276.556 25.8109 272.427 25.8109C267.097 25.8109 263.524 21.8664 263.524 15.4918V2.49441C263.524 1.20238 262.724 0.646205 262.168 0.646205V0.492188H267.097V0.646205C266.575 0.646205 265.74 1.20238 265.74 2.49441V15.834C265.74 20.7027 269.159 24.9852 273.625 24.9852C276.243 24.9852 279.448 23.1071 281.048 21.7509V2.49869C281.048 1.20666 280.214 0.650483 279.692 0.650483V0.492188H284.599Z"
      fill={fill}
    />
    <path
      d="M304.146 24.5531C309.258 24.5531 314.773 21.7808 314.773 13.6179C314.773 3.82068 309.134 1.2965 304.146 1.2965H298.507V24.5232C298.507 24.5232 302.173 24.5531 304.146 24.5531ZM294.687 0.646205V0.492188H304.454C309.66 0.492188 317.207 2.12648 317.207 13.4297C317.207 20.1123 312.309 25.3532 304.454 25.3532H294.687V25.1991C295.243 25.1991 296.287 24.5531 296.287 23.2568V2.49441C296.287 1.20238 295.243 0.646205 294.687 0.646205Z"
      fill={fill}
    />
    <path
      d="M326.2 25.1991C326.722 25.1991 327.526 24.643 327.526 23.3509V2.49441C327.526 1.20238 326.726 0.646205 326.2 0.646205V0.492188H331.069V0.646205C330.547 0.646205 329.743 1.20238 329.743 2.49441V23.3509C329.743 24.643 330.543 25.2291 331.069 25.2291V25.3532H326.2V25.1991Z"
      fill={fill}
    />
    <path
      d="M362.63 13.618C362.63 5.54923 356.867 0.680569 351.879 0.680569C347.164 0.680569 341.744 4.12885 341.744 11.8939C341.744 19.6247 346.796 25.0495 352.897 25.0495C357.055 25.0453 362.63 21.6269 362.63 13.618ZM365 13.4298C365 21.7168 357.885 25.8154 352.123 25.8154C346.33 25.8154 339.369 21.7168 339.369 12.8779C339.369 4.06895 346.33 0.0302734 352.341 0.0302734C357.239 0.0302734 365 3.08067 365 13.4298Z"
      fill={fill}
    />
    <path
      d="M46.0381 17.4981L40.3779 3.79054L34.4911 17.4981H46.0381ZM28.3047 25.199C29.1047 25.199 30.1529 24.707 30.769 23.3508L40.81 0H40.9341L50.8854 23.3508C51.4415 24.6428 52.7635 25.199 53.2897 25.199V25.353H47.6809V25.199C48.2371 25.199 48.5751 24.861 48.5751 24.2449C48.5751 23.9069 48.451 23.4748 48.2671 23.0128L46.3504 18.2981H34.1231L32.1209 23.0128C31.9669 23.3508 31.907 23.6588 31.907 23.9369C31.907 24.7369 32.4289 25.199 32.891 25.199V25.353H28.3047V25.199Z"
      fill={fill}
    />
  </Icon>
);
