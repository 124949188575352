import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useEffectOnce } from "react-use";

// Hooks
import { useHasMounted } from "../hooks/useHasMounted";

// Icons
import { SplashLogo } from "../icons/splash-logo";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;

  opacity: ${props => props.containerOpacity};
  transition: 750ms opacity ease;

  & img.splash-image {
    opacity: ${props => props.imageOpacity};
    transition: opacity 400ms ease 0ms;
    z-index: 1;
  }

  & svg.splash-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 75vw;

    opacity: ${props => props.logoOpacity};
    transition: 500ms opacity ease;
    z-index: 100;

    @media (max-width: 600px) {
      width: 80vw;
    }
  }

  & video.splash-video {
    z-index: 1;
    opacity: ${props => props.videoOpacity};
    transition: 150ms opacity ease;
  }

  & img.splash-image,
  & video.splash-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;

export const SplashWrapper = ({
  splashOpacity,
  isDesktop,
  isVideoLoaded,
  setIsVideoLoaded,
  triggerHideSplash,
}) => {
  const [opacity, setOpacity] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(0);

  const [randomDesktopNumber, setRandomDesktopNumber] = useState(null);
  const [randomMobileNumber, setRandomMobileNumber] = useState(null);

  // Rehydration stuff
  const hasMounted = useHasMounted();

  const data = useStaticQuery(graphql`
    {
      prismicSplash {
        data {
          mobile_splash_videos {
            mobile_splash_video {
              url
            }
            mobile_splash_image {
              alt
              fluid {
                srcWebp
                srcSetWebp
              }
            }
          }
          desktop_splash_videos {
            desktop_splash_video {
              url
            }
            desktop_splash_image {
              alt
              fluid {
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const showLogo = setTimeout(() => {
      setOpacity(1);
    }, 1750);
    return () => clearTimeout(showLogo);
  }, [isVideoLoaded]);

  const allDesktopContent = data.prismicSplash.data.desktop_splash_videos
    .filter(content => content.desktop_splash_video.url !== "")
    .map((content, index) => (
      <React.Fragment key={`single_desktop_splash_video_${index}`}>
        {content.desktop_splash_image.fluid !== null && (
          <img
            className="splash-image"
            onLoad={() => setImageOpacity(1)}
            onError={() => setImageOpacity(0)}
            srcSet={content.desktop_splash_image.fluid.srcSetWebp}
            src={content.desktop_splash_image.fluid.srcWebp}
            alt={content.desktop_splash_image.alt}
            loading={`eager`}
          />
        )}
        <video
          src={content.desktop_splash_video.url}
          loop
          muted
          autoPlay
          playsInline
          onLoadedData={() => setIsVideoLoaded(true)}
          className="splash-video"
        />
      </React.Fragment>
    ));

  const allMobileContent = data.prismicSplash.data.mobile_splash_videos
    .filter(content => content.mobile_splash_video.url !== "")
    .map((content, index) => (
      <React.Fragment key={`single_mobile_splash_video_${index}`}>
        {content.mobile_splash_image.fluid !== null && (
          <img
            className="splash-image"
            onLoad={() => setImageOpacity(1)}
            onError={() => setImageOpacity(0)}
            srcSet={content.mobile_splash_image.fluid.srcSetWebp}
            src={content.mobile_splash_image.fluid.srcWebp}
            alt={content.mobile_splash_image.alt}
            loading={`eager`}
          />
        )}
        <video
          src={content.mobile_splash_video.url}
          loop
          muted
          autoPlay
          playsInline
          onLoadedData={() => setIsVideoLoaded(true)}
          className="splash-video"
        />
      </React.Fragment>
    ));

  useEffectOnce(() => {
    const desktopNumber = Math.floor(Math.random() * allDesktopContent.length);
    setRandomDesktopNumber(desktopNumber);
  });

  useEffectOnce(() => {
    const mobileNumber = Math.floor(Math.random() * allMobileContent.length);
    setRandomMobileNumber(mobileNumber);
  });

  if (!hasMounted) {
    return null;
  }

  return (
    <SplashContainer
      containerOpacity={splashOpacity}
      logoOpacity={opacity}
      videoOpacity={isVideoLoaded ? 1 : 0}
      onClick={() => triggerHideSplash()}
      imageOpacity={imageOpacity}
    >
      <SplashLogo />

      {isDesktop ? (
        <>
          {randomDesktopNumber !== null
            ? allDesktopContent[randomDesktopNumber]
            : null}
        </>
      ) : (
        <>
          {randomMobileNumber !== null
            ? allMobileContent[randomMobileNumber]
            : null}
        </>
      )}
    </SplashContainer>
  );
};
