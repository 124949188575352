import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Icons
import { Logo } from "../icons/logo";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 25px 40px;
  z-index: 200;

  & *:focus {
    outline: ${props => props.textColor} dashed 1px;
  }

  @media (max-width: 767px) {
    padding: 23px 30px;
  }

  & ol {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0;
    margin: 0;

    & li {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.15em;

      color: ${props => props.textColor};

      &.flex-1 {
        flex: 1 1 0%;

        &:last-of-type {
          text-align: right;
        }
      }

      &.flex-2 {
        flex: 2;

        & a {
          display: block;
          margin: 0 auto;
          position: relative;
          line-height: 0;
          width: fit-content;
        }
      }

      & a {
        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
        transition: 250ms color ease;

        &.current {
          color: ${props => props.hoverTextColor};
        }

        &:hover {
          color: ${props => props.hoverTextColor};
        }
      }
    }
  }
`;

export const Header = ({
  location,
  pageType,
  textColor,
  hoverTextColor,
  isDesktop,
}) => {
  return (
    <HeaderWrapper
      textColor={textColor}
      hoverTextColor={hoverTextColor}
      bgColor={
        location === `/projects` || location === `/projects/`
          ? `#FFFDFA`
          : `transparent`
      }
      mobileBgColor={
        pageType === `Home`
          ? `#FFFDFA`
          : pageType !== `Studio`
          ? `#f8f1e8`
          : `#7A807E`
      }
    >
      <nav role="navigation" aria-label="Main">
        <ol>
          {isDesktop && (
            <li className="uppercase flex-1">
              <Link
                to={`/design`}
                className={pageType === `Design` ? `current` : ``}
              >
                Design
              </Link>
            </li>
          )}
          <li className="logo flex-2">
            <Link to={`/`} aria-label="home">
              <Logo fill={textColor} />
            </Link>
          </li>

          {isDesktop && (
            <li className="uppercase flex-1">
              <Link
                to={`/experience`}
                className={pageType === `Experience` ? `current` : ``}
              >
                Experience
              </Link>
            </li>
          )}
        </ol>
      </nav>
    </HeaderWrapper>
  );
};
