import React, { useEffect } from "react";
import styled from "styled-components";

// Transition
import Transition from "../transition/transition";

// Components
import { SplashWrapper } from "./splash";
import { Header } from "./header";
import { Footer } from "./footer";

const Site = styled.div`
  opacity: ${props => props.opacity};
  transition: 500ms opacity ease-in-out;
  transition-delay: 150ms;
`;

const Main = styled.main`
  background-color: ${props => props.background};
  transition: 500ms background-color ease-in-out;

  & *:focus {
    outline: ${props => props.focusColor} dashed 1px;
  }
`;

export const App = ({
  hideSplash,
  removeSplash,
  setRemoveSplash,
  pageType,
  location,
  designLink,
  experienceLink,
  pageBackgroundColor,
  isDesktop,
  isVideoLoaded,
  setIsVideoLoaded,
  setHideSplash,
  triggerHideSplash,
  isMobile,
  studioLink,
  children,
}) => {
  useEffect(() => {
    if (location.pathname !== `/` && isMobile) {
      setHideSplash(true);
      setRemoveSplash(true);
    }
  }, [location, isMobile]);

  return (
    <>
      {hideSplash ? (
        <Site opacity={removeSplash ? 1 : 0}>
          {pageType !== `hide-nav` && (
            <Header
              location={location.pathname}
              pageType={pageType}
              textColor={
                pageType === `Studio` ? `rgba(248, 241, 232, 1)` : `#000`
              }
              hoverTextColor={
                pageType === `Studio`
                  ? `rgba(248,241,232,0.5)`
                  : `rgba(0, 0, 0, 0.5)`
              }
              designLink={designLink}
              experienceLink={experienceLink}
              pageBackgroundColor={pageBackgroundColor}
              isDesktop={isDesktop}
            />
          )}

          <Main
            focusColor={
              pageType === `Studio` ? `rgba(248, 241, 232, 1)` : `#000`
            }
            background={pageBackgroundColor}
          >
            <Transition location={location}>{children}</Transition>
          </Main>

          {(pageType === `Home` || pageType === "Projects") && (
            <Footer
              location={location.pathname}
              pageType={pageType}
              textColor={
                pageType === `Studio` ? `rgba(248, 241, 232, 1)` : `#000`
              }
              hoverTextColor={
                pageType === `Studio`
                  ? `rgba(248,241,232,0.5)`
                  : `rgba(0, 0, 0, 0.5)`
              }
              designLink={designLink}
              experienceLink={experienceLink}
              studioLink={studioLink}
              pageBackgroundColor={pageBackgroundColor}
              isDesktop={isDesktop}
            />
          )}
        </Site>
      ) : (
        <></>
      )}
      {!removeSplash ? (
        <SplashWrapper
          isVideoLoaded={isVideoLoaded}
          setIsVideoLoaded={setIsVideoLoaded}
          setHideSplash={setHideSplash}
          splashOpacity={hideSplash ? 0 : 1}
          isDesktop={isDesktop}
          removeSplash={removeSplash}
          triggerHideSplash={triggerHideSplash}
        />
      ) : (
        <></>
      )}
    </>
  );
};
